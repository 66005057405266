/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import "~@angular/cdk/overlay-prebuilt.css";
@import "../../../node_modules/angular-calendar/css/angular-calendar.css";


// global styles


