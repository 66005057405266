// app-topbar

// body {
//   font-family: "Roboto";
//   margin: 0px;
//   background-color: #efefef;
// }

// p {
// 	font-family: "Roboto";
// }

// h1 {
// 	font-family: "Roboto";
// }

// h2 {
// 	font-family: "Roboto";
// }

// h3 {
// 	font-family: "Roboto";
// }

// h4 {
// 	font-family: "Roboto";
// }

// h5 {
// 	font-family: "Roboto";
// }

// h6 {
// 	font-family: "Roboto";
// }

// ul li { 
// 	font-family: "Roboto";
// }

// .hw-centered-content {
//   align-content: center;
// }
// .hw-centered-text {
//    text-align: center;
// }
// .hw-float-left {
//    float:left;
// }
// .hw-centered-text {
//   text-align: center;
// }

// .hw-center-content {
//   height: 100%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   margin-top: auto;
//   margin-bottom: auto;
// }

// .hw-right-text {
//   text-align: right;
// }
// .hw-float-right {
//   float: right;
// }
// .hw-float-none {
//   float: none;
// }
// .hw-font-size-16 {
//   font-size: 16px;
// }

// .hw-100 {
//   width: 100%;
// }
// .hw-50 {
//   width: 50%;
// }

// .hw-padding-right-025em {
//   padding-right: 0.25em;
// }

// .hw-padding-right-1em {
//   padding-right: 1em;
// }

// .hw-margin-right-1em {
//   margin-right: 1em;
// }

// .hw-margin-left-1em {
//   margin-left: 1em;
// }
// .hw-margin-left-05em {
//   margin-left: 0.5em;
// }

// .hw-margin-left--2em {
//   margin-left: -2em;
// }

// .hw-margin-top-05em {
//   margin-top: 0.5em;
// }

// .hw-margin-top-07em {
//   margin-top: 0.7em;
// }

// .hw-margin-top-1em {
//   margin-top: 1em;
// }

// .hw-margin-top-1-5em {
//   margin-top: 1.5em !important;
// }

// .hw-margin-top-2em {
//   margin-top: 2em;
// }

// .hw-margin-top--2em {
//   margin-top: -2em;
// }

// .hw-margin-top--1em {
//   margin-top: -1em;
// }

// .hw-float-right {
//   float: right;
// }

// .hw-padding-top-05 {
//   padding-top: 0.5em;
// }

// .hw-padding-top-1 {
//   padding-top: 1em;
// }

// .hw-padding-top-125 {
//   padding-top: 1.25em;
// }

// .hw-padding-bottom-05 {
//   padding-bottom: 0.5em;
// }

// .hw-margin-bottom-1em {
//   margin-bottom: 1em !important;
// }

// .hw-margin-top--1em {
//   margin-top: -1em !important;
// }

// .hw-margin-bottom-05em {
//   margin-bottom: 0.5em !important;
// }

// .hw-margin-bottom-3em {
//   margin-bottom: 3em !important;
// }

// .hw-margin-right-1em {
//   margin-right: 1em;
// }

// .hw-margin-right-05em {
//   margin-right: 0.5em !important;
// }

// .hw-padding-left-1em {
//   padding-left: 1em !important;
// }

// .hw-padding-left-2em {
//   padding-left: 2em !important;
// }

// .hw-field-caption {
//   font-size: 16px;
//   font-family: "Roboto";
//   font-weight: 500;
//   display: block;
// }
// .hw-combo-caption {
//   font-family: "Roboto";
//   font-weight: 500;
//   display: block;
// }

// .hw-right {
//   float: right;
// }

// .hw-align-end {
//   text-align: end;
// }

// .hw-text-align-center {
//   text-align: center;
// }

// .hw-float-right {
//   float: right;
// }

// .hw-float-left {
//   float: left;
// }

// .hw-text-align-left {
//   text-align: left;
// }

// .hw-text-align-right {
//   text-align: right;
// }

// .hw-grid-header {
//   font-size: 13px;
//   font-family: 'Roboto';
//   font-weight: 500;
// }
// .hw-grid-header-bold {
//   font-size: 13px;
//   font-family: 'Roboto';
//   font-weight: 800;
// }

// .hw-grid-cell {
//   font-size: 13px;
//   font-family: 'Roboto';
//   font-weight: 500;
// }
// .hw-grid-cell-bold {
//   font-size: 13px;
//   font-family: 'Roboto';
//   font-weight: 800;
// }

/* mobile styles */

// .hw-grid-header-mobile {
//   font-size: 10px;
//   font-family: 'Roboto';
//   font-weight: 500;
// }
// .hw-grid-header-bold-mobile {
//   font-size: 10px;
//   font-family: 'Roboto';
//   font-weight: 800;
// }

// .hw-grid-cell-mobile {
//   font-size: 9px;
//   font-family: 'Roboto';
//   font-weight: 500;
// }
// .hw-grid-cell-bold-mobile {
//   font-size: 9px;
//   font-family: 'Roboto';
//   font-weight: 800;
// }

// .card-icons {
//   color: #5a5a5a;
// }

// .hw-card {
//   box-shadow: 0 10px 16px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19) !important;
// }

// .hw-card-radius {
  
//   display: block !important;
//   height: 368px !important;
// }

// .dashboard-card {
//   // position: absolute !important;
//   margin: 15px !important;
//   // left: 15px !important;
//   // right: 15px !important;
//   // bottom: 15px !important;
//   width: 88%;
//   height: 88%;
//   // box-shadow: 0 10px 16px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19) !important;
//   border-radius: 4px !important;
// }

// .dashboard-card-double {
//     // position: absolute !important;
//     margin: 15px !important;
//     // left: 15px !important;
//     // right: 15px !important;
//     // bottom: 15px !important;
//     width: 94%;
//     height: 88%;
//     // box-shadow: 0 10px 16px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19) !important;
//     border-radius: 4px !important;
// }

// .widget-percent-increase {
//   color: #32cd32;
// }
// .widget-percent-decrease {
//   color: #dc143c;
// }

// .hw-cursor {
//   cursor: pointer;
// }

// .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
//   background-color: #3f51b5 !important;
// }

// .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
//   background-color: rgb(63 81 181 / 35%) !important;
// }

// simple widget circle
// .simple-data-widget-circle {
//   width: 250px;
//   height: 250px;
//   border-radius: 250px;
//   font-size: 25px;
//   text-align: center;
//   display: block;
//   margin-left: auto;
//   margin-right: auto;
//   margin-top: 60px;
//   // border: 20px solid #c1d5e0;
// }

// @media all and (min-width: 1280px) and (max-width: 1440px) {
//   .simple-data-widget-circle {
//     width: 210px;
//     height: 210px;
//     border-radius: 210px;
//   }
// }


// Content / Row
// .row {
//   display: flex;
//   flex-wrap: wrap;
// }

// .group-content {
//   border-top-right-radius: 4px !important;
//   border-top-left-radius: 4px !important;
//   border-bottom-right-radius: 4px !important;
//   border-bottom-left-radius: 4px !important;
//   box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
// }

// .highlighted-row {
//   padding: 10px 20px 10px 20px !important;
//   border-top-right-radius: 4px !important;
//   border-top-left-radius: 4px !important;
//   border-bottom-right-radius: 4px !important;
//   border-bottom-left-radius: 4px !important;
//   box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
//   // background-color: #3f51b5;
//   color:white;
//   background: linear-gradient(90deg, #020024 0%, #090979 35%, #065aaf 100%) !important;
// }


// // Stepper
// .mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover {
//   height: 40px;
//   border-radius: 20px;
//   text-align: center;
//   border: 3px solid #3f51b5;
//   background: white;
// }

// .mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:active {
//   height: 40px;
//   border-radius: 20px;
//   text-align: center;
//   border: 3px solid #3f51b5;
//   background: white;
// }

// .mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:focus {
//   height: 40px;
//   border-radius: 20px;
//   text-align: center;
//   border: 3px solid #3f51b5;
//   background: white;
// }

// .mat-dialog-container {
//   border-radius: 4px !important;
// }

// Mat tree
// .mat-tree-node {
//   min-height: 20px !important;
// }

// Missing data
// .hw-missing-data {
//   align-content: center;
//   text-align: center;
//   font-size: 20px;
//   color: red;
//   font-weight: 500;
//   font-family: "Roboto";
//   padding-top: 130px;
// }

// Drag-drop
// .cdk-drag-preview {
//   box-sizing: border-box !important;
//   // border-radius: 4px !important;
//   box-shadow: 0 15px 5px -17px rgb(0 0 0), 0 10px 1px 0px rgb(0 0 0 / 0%), 0 3px 14px 2px rgb(0 0 0 / 64%);
// }

// HW nem title
// .hw-new-title {
//   font-size: 16px;
//   font-weight: 600;
//   padding-top: 1.5em;
// }

// HighChart title
// .highcharts-title {
//   fill: #000000 !important;
//   font-size: 14px !important;
//   color: black !important;
// }

// .highcharts-root text {
//   stroke-width: 0;
//   font-family: "Roboto";
//   font-weight: 500;
// }

// .mat-button-toggle-checked {
//   background-color: #3f51b5 !important;
//   color: white !important;
// }

/* highcharts table */

// /* chart-table */
// .highcharts-data-table table {
//   border-collapse: collapse !important;
//   border-spacing: 0 !important;
//   background: white !important;
//   min-width: 100% !important;
//   // margin-top: 10px !important;
//   font-family: sans-serif !important;
//   font-size: 0.9em !important;
// }
// .highcharts-data-table td, .highcharts-data-table th, .highcharts-data-table caption {
//   border: 1px solid #ececec !important;
//   padding: 0.4em !important;
//   text-align: center;
// }
// .highcharts-data-table tr:nth-child(even), .highcharts-data-table thead tr {
//   background: #f8f8f8 !important;
// }
// .highcharts-data-table tr:hover {
//   background: #e8e8e8 !important
// }
// .highcharts-data-table caption {
//   border-bottom: none !important;
//   font-size: 14px !important;
//   font-weight: 700 !important;
//   padding: 1em !important;
// }

// .highcharts-button-box:hover {
//   background: white !important;
// }


// analysis panel no data

// .analysis-no-data {
//   padding-top: 200px;
//   align-items: center;
//   color: crimson;
//   font-family: 'Roboto';
//   font-weight: 600;
//   font-size: 18px;
//   font-weight: 600;
//   border: 2px solid crimson !important;
//   border-radius: 16px;
//   padding: 0.7em;
//   width: 300px;
//   text-align: center;
//   margin: 3em auto 1em auto;
// }

// Mat-expansion

//.mat-expansion-panel-header.mat-expanded {
//  height: auto !important;
//  padding: 20px 25px 20px 25px !important;
//}

//.mat-expansion-panel-header {
//  height: auto !important;
//  padding: 10px 20px 10px 18px !important;
//}

// Mat button
// .mat-success {
//   background-color: #155724;
//   color: white;
// }

// // Toggle buttons
// .mat-button-toggle-group-appearance-standard {
//   font-weight: 500 !important;
//   font-size: 15px !important;
// }

// .mat-button-toggle-group {
//   flex-wrap: wrap !important;
// }

// .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
//   padding: 0 20px !important;
// }

// .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
//   line-height: 40px !important;
// }

// .mat-button-toggle-group-appearance-standard {
//   border: solid 0px rgba(0,0,0,.12) !important;
//   box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%) !important;
// }

// Container

// .hw-group-container  {
//   padding: 20px 25px 50px 25px !important;
//   border-top-right-radius: 4px !important;
//   border-top-left-radius: 4px !important;
//   border-bottom-right-radius: 4px !important;
//   border-bottom-left-radius: 4px !important;
//   box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
//   background-color: white;
// }

// .mat-drawer-container {
//   background-color: #efefef;
//   color: rgba(0,0,0,.87);
// }

// .main_content {
//   width: auto !important;
//   height: 100% !important;
//   margin: 0em 1em 1em 1em;
// }

// @media all and (min-width: 100px) and (max-width: 1029px) {
//   .main_content {
//     margin: 5em 0em 0em 0em !important;
//   }
// }

// @media all and (min-width: 1029px) and (max-width: 1259px) {
//   .main_content {
//     margin: 5em 1em 1em 1em !important;
//   }
// }

// .highcharts-container  {
//   border-top-right-radius: 4px !important;
//   border-top-left-radius: 4px !important;
//   border-bottom-right-radius: 4px !important;
//   border-bottom-left-radius: 4px !important;
//   // box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%) !important;
//   margin-bottom: 1em;
//   width: auto;
// }

// .highcharts-data-table  {
//   border-top-right-radius: 4px !important;
//   border-top-left-radius: 4px !important;
//   border-bottom-right-radius: 4px !important;
//   border-bottom-left-radius: 4px !important;
//   box-shadow: 0px 0px 0px 0px #ffffff, 0px 0px 0px 0px #ffffff, 0px 0px 0px 0px #ffffff !important;
//   background-color: white !important;
// }

// .hw-font-weight-500 {
//    font-weight: 500;
// }

// .hw-font-size-20 {
//   font-size: 20px;
// }


// .hw-white-space-initial {
//    white-space: initial !important;
// }

// .hw-padding-bottom-05 {
//   padding-bottom: 0.5em;
// }

// .hw-margin-bottom-1em {
//   margin-bottom: 1em !important;
// }

// .hw-margin-bottom-2em {
//   margin-bottom: 2em !important;
// }

// .refresh-button {
//   text-align: end;
//   margin-top: 1em;
// }

// .hw-webkit-fill {
//   width: -webkit-fill-available;
// }

// .hw-title-text {
//   font-size: 16px;
//   font-weight: 600;
//   padding: 20px 0px 20px 0px;
// }

/* Plusz classok */
// .hw-container-background {
//   margin: -60px 40px 40px 40px;
//   position: relative !important;
//   padding: 2em;
//   background-color: #fff;
// }

// @media screen and (max-width: 840px) {
//   .hw-container-background {
//     margin: -60px 0px 0px 0px;
//     padding: 1em;
//   }
// }

// .trustee-dashboard-panel {
//   padding: 25px;
//   margin: 10px;
//   box-shadow: 0 2px 0 0 transparent, 0 2px 5px 0 rgb(0 0 0 / 22%);
// }

/* Grid title + rounded panel */
// .hw-grid-title {
//   font-size: 16px;
//   font-weight: 600;
//   padding-top: 1.5em;
// }

// .hw-rounded-panel {
//   overflow: hidden;
//   padding: 0px 25px 0px 25px !important;
//   border-top-right-radius: 4px !important;
//   border-top-left-radius: 4px !important;
//   border-bottom-right-radius: 4px !important;
//   border-bottom-left-radius: 4px !important;
//   box-shadow: 0px 0px 1px -1px rgb(0 0 0 / 10%), 0px 0px 4px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 12%);
//   background-color: white;
// }

// /* Font size */
// .hw-font-size-10 {
//   font-size: 10px;
// }

// .hw-font-size-11 {
//   font-size: 11px;
// }

// .hw-font-size-12 {
//   font-size: 12px;
// }

// .hw-font-size-13 {
//   font-size: 13px;
// }

// .hw-font-size-14 {
//   font-size: 14px;
// }

// .hw-font-size-15 {
//   font-size: 15px;
// }

// .hw-font-size-16 {
//   font-size: 16px;
// }

// .hw-font-size-17 {
//   font-size: 17px;
// }

// .hw-font-size-18 {
//   font-size: 18px;
// }

// .hw-font-size-19 {
//   font-size: 19px;
// }

// .hw-font-size-20 {
//   font-size: 20px;
// }

// /* Hotel selector - mat-form-field */
// .mat-form-field-appearance-fill .mat-form-field-flex {
//   background-color: rgb(255 255 255 / 0%) !important;
// }

// .cleaning-type-editor-dialog {
//   height:auto;
// }

/* Smooth scroll effekt miatt */
html {
  scroll-behavior: smooth !important;
}

/* header */
.canvas {
  background-color: #03a9f4;
  height: 200px;
}

.export-icon-row {
  text-align: end;
  margin-top: 85px;
  padding-right: 40px;
}

@media screen and (max-width: 840px) {
  .canvas {
      background-color: #03a9f4;
      height: 165px !important;
  }

  .export-icon-row {
      text-align: end;
      margin-top: 61px;
      padding-right: 15px;
  }
}

.export-icons {
  color: white;
  font-size: 35px;
}

/* Body */
body {
  font-family: "Roboto";
  margin: 0px;
  background-color: #efefef;
}

/* Expansion panel */
.mat-expansion-panel-header.mat-expanded {
  // background-color: #f5f5f5 !important;
  margin-bottom: 1em;
}

/* Divider Line */
body .ui-widget-overlay {
  background-color: rgba(88,87,92,.8);
}

/* mat-label szimuláció */ 
.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgb(255 255 255 / 0%) !important;
}

/* Dashboart */

// Trustee
.trustee-dashboard-panel {
  padding: 25px;
  margin: 10px;
  box-shadow: 0 2px 0 0 transparent, 0 2px 5px 0 rgb(0 0 0 / 22%);
}

// Maintenance
.maintenance-leader-dashboard-panel {
  padding: 25px;
  margin: 10px;
  box-shadow: 0 2px 0 0 transparent, 0 2px 5px 0 rgb(0 0 0 / 22%);
}
.dashboard-panel {
  padding: 25px;
  margin: 10px;
  box-shadow: 0 2px 0 0 transparent, 0 2px 5px 0 rgb(0 0 0 / 22%);
}
/* Hotel selector - mat-form-field */
.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgb(255 255 255 / 0%) !important;
}

/* Dialog */
.mat-dialog-container {
  border-radius: 4px !important;
}

/* Expansion panel / indicator */ 
.mat-expansion-indicator::after {
  border-color: black;
}

.mat-expansion-panel-spacing {
  margin: 0px !important;
}
 
/* OLD */
@media (max-width: 599px) {
  .cleaning-type-editor-dialog {
    height: auto;
    width: 100vw !important;
    max-width: 100vw !important;
  }
}

@media (max-width: 599px) {
  .cleaning-group-editor-dialog {
    height: auto;
    width: 100vw !important;
    max-width: 100vw !important;
  }
}

.hw-boolean-cell {
  font-size: 25px;
  color: #2196f3
}

.cdk-global-scrollblock {
  overflow-y: hidden !important;
  overflow-x: hidden !important;
}

/* Plus button / Scroll to top */
.plus {
  color: white;
  position: absolute;
  top: 0em;
  display: block;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  padding: 0;
  margin: 0;
  line-height: 55px;
  font-size: 35px;
  font-family: 'Roboto';
  font-weight: 300;
  -webkit-animation: plus-out 0.3s;
  animation: plus-out 0.3s;
  transition: all 0.3s;
  // background-color: #03a9f4 !important;
}

.float-button {
  background-color: #03a9f4 !important;
  border: none !important;
  position: fixed !important;
  right: 1em !important;
  bottom: 0 !important;
  width: 55px !important;
  height: 55px !important;
  border-radius: 50% !important;
  cursor: pointer !important;
  box-shadow: 0 2px 5px #666 !important;
}

.float-button:hover {
  background-color: #0099e0 !important;
}

.add-icon {
  color: #ffffff !important;
  font-size: 16px;
}

.command-button {
  float: right;
  width: 68px;
  margin-left: 10px;
  margin-bottom: 30px;
}

.hw-arrow-up:hover {
  background-color: #c7c7c7;
}

.hw-arrow-up {
  color: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  padding: 0;
  margin: 0;
  line-height: 55px;
  font-size: 35px;
  font-family: Roboto;
  font-weight: 300;
  -webkit-animation: plus-out .3s;
  animation: plus-out .3s;
  transition: all .3s;
  background-color: #b3b3b3;
  display: block;
  border-radius: 50%!important;
}

.scroll-top-float-button, .scroll-top-float-button:hover {
  position: fixed!important;
  right: 1em;
  bottom: 0!important;
  width: 55px!important;
  height: 55px!important;
  cursor: pointer!important;
  background-color: #adadad!important;
  border: none!important;
  border-radius: 50%!important;
}

.scroll-top-float-button {
  background-color: #adadad!important;
  border: none!important;
  border-radius: 50%!important;
}

.scroll-top-float-button-single {
  margin-bottom: 30px;
  margin-right: 0em;
}
.scroll-top-float-button-single, .scroll-top-float-button-single:hover {
  position: fixed!important;
  right: 1em;
  bottom: 0!important;
  width: 55px!important;
  height: 55px!important;
  cursor: pointer!important;
  background-color: #adadad!important;
  border: none!important;
  border-radius: 50%!important;
}
.scroll-top-float-button {
  margin-bottom: 6em;
  margin-right: 0em;
}

/* User group modal width */
@media (max-width: 599px) {
  .user-group-modal {
    width: 100vw !important;
    max-width: 100vw !important;
  }
}

/* Password change dialog */ 
 @media (max-width: 599px) {
  .password-change-dialog {
    height: auto;
    width: 100vw !important;
    max-width: 100vw !important;
  }
}

@media screen and (max-width: 1024px) and (min-width: 600px) {
  .password-change-dialog {
    height: auto;
    width: 70vw !important;
    max-width: 70vw !important;
  }
}

/* Lost and found dialog */ 
@media (max-width: 599px) {
  .lost-and-found-dialog {
    height: auto;
    width: 100vw !important;
    max-width: 100vw !important;
  }
}

@media screen and (max-width: 1024px) and (min-width: 600px) {
  .lost-and-found-dialog {
    height: auto;
    width: 70vw !important;
    max-width: 70vw !important;
  }
}

th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
  z-index: 0 !important;
}

/* Calendar - Days label */
.cal-month-view .cal-header .cal-cell {
  text-transform: capitalize;
  font-weight: 500;
}

/* Sidebar z-index áttűnések */
.mat-slide-toggle-thumb-container {
  z-index: auto !important;
}

/* Task + HK + public dialog - mobile */
@media (max-width: 599px) {
  .add-task-dialog {
    height: auto;
    width: 100vw !important;
    max-width: 100vw !important;
  }

  .add-accountable-user-dialog {
    height: auto;
    width: 100vw !important;
    // max-width: 100vw !important;
  }

  .maintenance-type-editor-dialog {
    height: auto;
    width: 100vw !important;
    max-width: 100vw !important;
  }

  .public-area-editor-dialog {
    height: auto;
    width: 100vw !important;
    max-width: 100vw !important;
  }

  .cdk-overlay-pane {
    max-width: 100% !important;
  }
}

.mat-calendar-body-cell-content, .mat-date-range-input-separator {
  border-color: #00000014;
}

@media screen and (max-width: 1024px) and (min-width: 600px) {
  .add-accountable-user-dialog {
    height: auto;
    width: 55vw !important;
  }
}

@media screen and (max-width: 1299px) and (min-width: 1025px) {
  .add-accountable-user-dialog {
    height: auto;
    width: 40vw !important;
  }
}

.fro-action-buttons {
  width: 100%;
  float: right !important;
  text-align: right !important;
  margin-top: 0.5em;
  padding-bottom: 0.5em !important;
}

// material style override
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: white !important;
}

.mat-mdc-form-field-focus-overlay {
  background-color: white !important;
}


.hw-popup-title {
  font-family: "Roboto" !important;
  font-weight: 600 !important;
  font-size: 20px !important;
  margin-bottom: 1em !important;
}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-bottom: 0px !important;
  margin-top: -0.0625em;
}

.mat-mdc-form-field-infix {
  min-height: 35px !important;
}

.mdc-text-field {
  padding: 0px !important;
  width: 100% !important;
}

// .mdc-list-item.mdc-list-item--selected {
//   background: #dcdcdc !important;
// }

.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text, .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #000000 !important;
}

.mat-mdc-fab, .mat-mdc-mini-fab {
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 0%), 0px 6px 10px 0px rgb(0 0 0 / 0%), 0px 1px 18px 0px rgb(0 0 0 / 0%) !important;
}